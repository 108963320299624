.accueil{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: rgb(236, 122, 225);
    color: white;
}

.image_logo{
    max-height: 10vh;
    max-width: 10vh;
}

#login{
    min-width: 100px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding-bottom: 0.6;
    color: rgb(255, 0, 128);
    font-style: italic;
}