.statusdating{
    width: 50px;
    height: 25px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background-color: #ee1c1c;
    color:#ffffff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

#datinglist, #datingnotification{
    display: none;
}