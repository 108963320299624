.Landing{
    text-align: center;
}

/*#landing{
    display: grid;
    place-content: center;
}*/

#container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
}

.circle{
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-color: rgb(255, 0, 128);
    position: absolute;
    opacity: 0;

    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn{
    from{
        transform: scale(.5, .5);
        opacity: .5;
    }
    to{
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.item{
    z-index: 100;
    padding: 5px;
}

.item img{
    width: 100px;
}