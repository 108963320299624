input[type="checkbox"][id^="preferindia"],[id^="preferpakistan"],[id^="preferafghan"],
[id^="preferbengali"],[id^="preferindonesia"],[id^="preferiran"],
[id^="preferturkey"],[id^="prefermalaysia"],[id^="manpreferindia"],[id^="manpreferpakistan"],[id^="manpreferafghan"],
[id^="manpreferbengali"],[id^="manpreferindonesia"],[id^="manpreferiran"],
[id^="manpreferturkey"],[id^="manprefermalaysia"],[id^="lifetravel"],[id^="lifenature"],
[id^="lifesport"],[id^="lifebeach"] {
    display: none;
}

label[for="preferindia"],[for="preferpakistan"],[for="preferafghan"],
[for="preferbengali"],[for="preferindonesia"],[for="preferiran"],
[for="preferturkey"],[for="prefermalaysia"],[for="manpreferindia"],[for="manpreferpakistan"],[for="manpreferafghan"],
[for="manpreferbengali"],[for="manpreferindonesia"],[for="manpreferiran"],
[for="manpreferturkey"],[for="manprefermalaysia"],[for="lifetravel"],[for="lifenature"],
[for="lifesport"],[for="lifebeach"] {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
}

label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
}

label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}
  
:checked + label {
    border-color: #ddd;
}

:checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
}
  
:checked + label img {
    transform: scale(0.9);
    /* box-shadow: 0 0 5px #333; */
    z-index: -1;
}

#starterone, #starterphone, #starterlogin, #startertwo, #starterthree, #starterfour, #starterfive, #starterfiveman, 
#startersix, #starterseven, #startereight, #starternine{
    display: none;
}